/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'error': {
    width: 51,
    height: 51,
    viewBox: '0 0 51 51',
    data: '<path pid="0" d="M51 25.5C51 39.561 39.561 51 25.5 51S0 39.561 0 25.5 11.439 0 25.5 0 51 11.439 51 25.5zm-2.887 0c0-12.47-10.145-22.613-22.613-22.613-12.469 0-22.613 10.142-22.613 22.613 0 12.47 10.144 22.613 22.613 22.613 12.468 0 22.613-10.142 22.613-22.613z" _fill="#fff"/><path pid="1" d="M18.776 16.476l6.805 6.805 6.274-6.275a1.625 1.625 0 012.299 2.298l-6.275 6.275 6.276 6.276a1.625 1.625 0 01-2.298 2.299l-6.276-6.277-6.807 6.807a1.625 1.625 0 11-2.298-2.298l6.807-6.807-6.805-6.805a1.625 1.625 0 112.298-2.298z" _fill="#fff"/>'
  }
})
